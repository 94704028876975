import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-use';
import clsx from 'clsx';

import { useUser } from '@shared/hooks/useUser';
import { Routes } from '@shared/enums';
import { Button } from '@shared/ui/buttons/Button';

import { useLanguage } from '@hooks/useLanguage';
import useMinWidthMediaQuery from '@hooks/useMinWidthMediaQuery';

interface HelpButtonsProps {
  onClick?: () => void;
}

export const CabinetPathButton = ({
  opened = false,
  children,
  route,
  onClick,
}: {
  opened?: boolean | undefined;
  route: Routes;
  children: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Button
      as="link-in"
      size="tiny"
      to={route}
      className={clsx(
        'whitespace-nowrap border-transparent border-2 border-solid',
        opened && '!bg-white text-black border-2 border-solid border-black',
      )}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const HelpButtons: React.FC<HelpButtonsProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const language = useLanguage();

  const [user] = useUser();
  const { pathname } = useLocation();

  const isLargeScreen = useMinWidthMediaQuery('lg');

  const isVolunteerCabinet = pathname?.includes(Routes.VolunteerCabinet);
  const isCustomerCabinet = pathname?.includes(Routes.CustomerCabinet);

  const CustomerButton = useCallback(
    () =>
      user?.isMember && language === 'uk' ? (
        <CabinetPathButton opened={isCustomerCabinet} route={Routes.CustomerCabinet} onClick={onClick}>
          {t('i-very-need-help')}
        </CabinetPathButton>
      ) : null,
    [user, t, language, onClick, isCustomerCabinet],
  );

  const VolunteerButton = useCallback(
    () => (
      <CabinetPathButton
        opened={isVolunteerCabinet}
        route={`${Routes.VolunteerCabinet}?category=online-medicaments` as Routes}
        onClick={onClick}
      >
        {t('i-want-to-help')}
      </CabinetPathButton>
    ),
    [t, onClick, isVolunteerCabinet],
  );

  return (
    <div className={clsx(isLargeScreen && 'flex flex-row space-x-5', !isLargeScreen && 'flex flex-col space-y-5')}>
      <VolunteerButton />
      <CustomerButton />
    </div>
  );
};
